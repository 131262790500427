jQuery(function($) {
  var $smooth  = $('a[href^="#"]');
  // #で始まるアンカーをクリックした場合に処理
  $smooth.on('click', function() {
    // スクロールの速度
    var speed = 800;// ミリ秒
    // アンカーの値取得
    var href= $(this).attr("href");
    // 移動先を取得
    var target = $(href === '#top' || href === '#' || href === '' ? 'html' : href);
    // var nav = $("#nav").height();
    var header =  $('#header .inner').outerHeight();
    // 移動先を数値で取得
    var position = target.offset().top - header;
    // スムーススクロール
     if( !$('body html').is(':animated') ) {
        $('body,html').stop().animate({scrollTop:position}, speed, 'swing');
    }
    return false;
  });
});

var btnOpen = document.getElementById('btn-open');
var close = document.getElementById('close');
var popup = document.getElementById('popup')
var over = document.getElementById('over');

btnOpen.addEventListener('click', function(){
  popupOpen();
});

close.addEventListener('click', function(){
  popupClose();
});

function popupOpen(){
  popup.style.display = "block";
  over.style.display = "block";
}

function popupClose(){
  popup.style.display = "none";
  over.style.display = "none";
}



window.onscroll = function (){ heightSize()};
var pox = document.body.offsetHeight;
var pox1 = 500;
console.log(pox1);
function heightSize(){
  var x = document.getElementById('footer-fixed');
  if(document.body.scrollTop > 60 || document.documentElement.scrollTop > 60){
    x.style.position = "fixed";
    
  }
  else{
    x.style.position = "static";
  }
  
}

var w = $(window), d = $(document);
w.scroll(function() {
  var pos = document.getElementById('copyright');
   if(w.scrollTop() + w.height() == d.height())
   {
    pos.style.marginBottom = "23%";
   } 
});
